// src/components/Agents/AgentTable.js

import React from "react";
import { Table, Button } from "../UIComponents";

const AgentTable = ({ agents, onEdit, onDelete }) => {
  console.log("AgentTable: Received agents:", agents);

  if (!agents) {
    console.error("AgentTable: agents prop is undefined");
    return <p>Error: Unable to load agents.</p>;
  }

  if (!Array.isArray(agents)) {
    console.error("AgentTable: agents prop is not an array:", agents);
    return <p>Error: Invalid agents data.</p>;
  }

  if (agents.length === 0) {
    return <p>No agents available.</p>;
  }

  const headers = [
    "Agent Name",
    "Voice",
    "Agent ID",
    "Model",
    "Temperature",
    "General Prompt",
    "Actions",
  ];

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength - 3) + "...";
  };

  const rows = agents
    .map((agent) => {
      if (!agent || typeof agent !== "object") {
        console.error("AgentTable: Invalid agent object:", agent);
        return null;
      }

      console.log("Raw agent data:", agent);
      
      // Prefer response_engine data when available, fallback to llm data
      // Extract LLM and agent data
      const responseEngine = agent.response_engine || {};
      const llm = agent.llm || {};
      
      console.log(`Data for agent ${agent.agent_name}:`, {
        agent_name: agent.agent_name,
        voice_id: agent.voice_id,
        agent_id: agent.agent_id,
        response_engine: responseEngine,
        llm: llm
      });

      return [
        agent.agent_name || "N/A",
        agent.voice_id || "N/A",
        agent.agent_id || "N/A",
        llm.model || "N/A",
        llm.model_temperature !== null && llm.model_temperature !== undefined
          ? llm.model_temperature.toFixed(2)
          : "N/A",
        <span title={agent.general_prompt || responseEngine.general_prompt || llm.general_prompt || "N/A"}>
          {truncateText(agent.general_prompt || responseEngine.general_prompt || llm.general_prompt || "N/A", 50)}
        </span>,
        <div
          key={`actions-${agent.agent_id}`}
          className="flex flex-col sm:flex-row"
        >
          <Button onClick={() => onEdit(agent)} className="mr-2 mb-2 sm:mb-0">
            Edit
          </Button>
          <Button
            onClick={() => onDelete(agent.agent_id)}
            variant="danger"
            className="mt-2 sm:mt-0"
          >
            Delete
          </Button>
        </div>,
      ];
    })
    .filter(Boolean); // Remove any null rows

  return <Table headers={headers} rows={rows} />;
};

export default AgentTable;
