import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { handleError } from "../utils/toast";
import {
  fetchAgents,
  fetchVoices,
  fetchLLMs,
  createAgent as apiCreateAgent,
  updateAgent as apiUpdateAgent,
  deleteAgent as apiDeleteAgent,
} from "../utils/api";

const useAgents = () => {
  const [retellAgents, setRetellAgents] = useState([]);
  const [retellVoices, setRetellVoices] = useState([]);
  const [retellLLMs, setRetellLLMs] = useState([]);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [loadingVoices, setLoadingVoices] = useState(false);
  const [loadingLLMs, setLoadingLLMs] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [error, setError] = useState(null);

  const fetchAgentsAndLLMs = useCallback(async () => {
    console.log("Fetching agents and LLMs...");
    setLoadingAgents(true);
    setLoadingLLMs(true);
    setError(null);
    try {
      const [agentsResponse, llmsResponse] = await Promise.all([
        fetchAgents(),
        fetchLLMs(),
      ]);

      if (
        agentsResponse &&
        Array.isArray(agentsResponse.agents) &&
        llmsResponse &&
        Array.isArray(llmsResponse.llms)
      ) {
        const agents = agentsResponse.agents;
        const llms = llmsResponse.llms;
        console.log("LLMs from backend:", llms);

        // Create LLM mapping with proper error checking
        const llmMapping = {};
        llms.forEach((llm, index) => {
          if (!llm.llm_id) {
            console.warn(`LLM at index ${index} has no llm_id:`, llm);
            return;
          }
          llmMapping[llm.llm_id] = llm;
        });

        console.log("LLM Mapping:", llmMapping);
        console.log("Initial agents:", agents);

        const mergedAgents = agents.map((agent) => {
          if (!agent.response_engine) {
            console.warn(`Agent ${agent.agent_name} has no response_engine:`, agent);
            return agent;
          }

          const llmId = agent.response_engine.llm_id;
          if (!llmId) {
            console.warn(`Agent ${agent.agent_name} has no llm_id in response_engine:`, agent.response_engine);
            return agent;
          }

          const llmData = llmMapping[llmId];
          if (!llmData) {
            console.warn(`No LLM data found for agent ${agent.agent_name} with llm_id ${llmId}`);
            return {
              ...agent,
              llm: {
                general_prompt: agent.response_engine?.general_prompt || "N/A",
                model: "N/A",
                model_temperature: 0
              }
            };
          }

          const mergedLLM = {
            ...llmData,
            general_prompt: agent.response_engine?.general_prompt || llmData.general_prompt || "N/A",
            model: llmData.model || "N/A",
            model_temperature: llmData.model_temperature || 0
          };

          console.log(`Merged data for agent ${agent.agent_name}:`, {
            original_llm: llmData,
            response_engine: agent.response_engine,
            merged_llm: mergedLLM,
            final_general_prompt: mergedLLM?.general_prompt
          });

          return {
            ...agent,
            llm: mergedLLM
          };
        });

        setRetellAgents(mergedAgents);
        setRetellLLMs(llms);
      } else {
        throw new Error("Invalid data structure received from the backend.");
      }
    } catch (error) {
      console.error("Error fetching agents and LLMs:", error);
      handleError("Failed to fetch agents and LLMs.", error);
      setRetellAgents([]);
      setRetellLLMs([]);
      setError("Failed to fetch agents and LLMs. Please try again later.");
    } finally {
      setLoadingAgents(false);
      setLoadingLLMs(false);
    }
  }, []);

  const fetchRetellVoices = useCallback(async () => {
    setLoadingVoices(true);
    setError(null);
    try {
      console.log("Fetching voices...");
      const response = await fetchVoices();
      console.log("Voices fetched:", response);
      if (response && Array.isArray(response.voices)) {
        setRetellVoices(response.voices);
      } else {
        throw new Error("Invalid voices data structure.");
      }
    } catch (err) {
      console.error("Error fetching voices:", err);
      handleError("Failed to fetch voices.", err);
      setRetellVoices([]);
      setError("Failed to fetch voices. Please try again later.");
    } finally {
      setLoadingVoices(false);
    }
  }, []);

  useEffect(() => {
    fetchAgentsAndLLMs();
    fetchRetellVoices();
  }, [fetchAgentsAndLLMs, fetchRetellVoices]);

  const handleCreateAgent = useCallback(
    async (data) => {
      console.log("Creating agent:", data);
      setLoadingAgent(true);
      setError(null);
      try {
        const response = await apiCreateAgent({
          agent: {
            agent_name: data.agent_name,
            voice_id: data.voice_id,
          },
          llm: {
            model: "gpt-4o",
            model_temperature: 0,
            general_prompt: data.general_prompt,
            general_tools: data.general_tools,
          },
        });
        toast.success("Agent created successfully.");
        console.log("Agent created successfully:", response);
        await fetchAgentsAndLLMs();
      } catch (error) {
        console.error("Error creating agent:", error);
        handleError("Failed to create agent.", error);
        setError("Failed to create agent. Please check your input and try again.");
      } finally {
        setLoadingAgent(false);
      }
    },
    [apiCreateAgent, fetchAgentsAndLLMs]
  );

  const handleUpdateAgent = useCallback(
    async (data) => {
      console.log("Updating agent:", data);
      setLoadingAgent(true);
      setError(null);
      try {
        console.log("Updating agent with data:", data);

        // Include general_prompt in the update
        const response = await apiUpdateAgent(data.agent_id, {
          agent_name: data.agent_name,
          voice_id: data.voice_id,
          general_prompt: data.general_prompt,
          general_tools: data.general_tools,
          llm_id: data.llm_id,
        });
        toast.success("Agent updated successfully.");
        console.log("Agent updated successfully:", response);
        await fetchAgentsAndLLMs();
      } catch (error) {
        console.error("Error updating agent:", error);
        handleError("Failed to update agent.", error);
        setError("Failed to update agent. Please check your input and try again.");
      } finally {
        setLoadingAgent(false);
      }
    },
    [apiUpdateAgent, fetchAgentsAndLLMs]
  );

  const handleEditAgent = useCallback(
    (agent) => {
      try {
        setCurrentAgent(agent);
      } catch (error) {
        console.error("Error setting current agent:", error);
        handleError("Failed to edit agent.", error);
        setError("Failed to edit agent. Please try again.");
      }
    },
    []
  );

  const handleDeleteAgent = useCallback(
    async (agentId) => {
      console.log("Deleting agent with ID:", agentId);
      setLoadingAgent(true);
      setError(null);
      try {
        await apiDeleteAgent(agentId);
        toast.success("Agent deleted successfully.");
        await fetchAgentsAndLLMs();
      } catch (error) {
        console.error("Error deleting agent:", error);
        handleError("Failed to delete agent.", error);
        setError("Failed to delete agent. Please try again later.");
      } finally {
        setLoadingAgent(false);
      }
    },
    [apiDeleteAgent, fetchAgentsAndLLMs]
  );

  return {
    retellAgents,
    retellVoices,
    retellLLMs,
    currentAgent,
    setCurrentAgent,
    loadingAgents,
    loadingAgent,
    loadingVoices,
    loadingLLMs,
    handleCreateAgent,
    handleUpdateAgent,
    handleEditAgent,
    handleDeleteAgent,
    error,
  };
};

export default useAgents;
